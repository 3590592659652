import { browser } from '$app/environment';
import { env } from '$env/dynamic/public';
import { OpenAPI } from '@mandelbaum/api-client';

if (import.meta.env.SSR && !browser) {
  const { env: privateEnv } = await import('$env/dynamic/private');
  OpenAPI.basePath = privateEnv.INTERNAL_BACKEND_URL || env.PUBLIC_BACKEND_URL;
} else {
  OpenAPI.basePath = env.PUBLIC_BACKEND_URL;
}
